import React from 'react';

// Addon dependencies imported

// Utilized components imported
import '../../../styles/custom/addon/made-with-love.css';

const MadeWithLove = () => {
	// console.log('>> TEST RENDER MADE WITH LOVE:');

	return (
		<div className="made-with-love">
			<h2 className="start-tribute-with">This site Made with:</h2>
			<ul className="tool-box">
				<li className="tool">
					<a href="https://webflow.com" target="_blank">
						<img
							className="tool-shape"
							src="icons/webflow-icon.png"
							alt="Webflow"
						/>
						<h3 className="tool-label">Webflow</h3>
					</a>
				</li>
				<li className="tool">
					<a href="https://www.gatsbyjs.com" target="_blank">
						<img
							className="tool-shape"
							src="icons/gatsbyjs-icon.svg"
							alt="Gatsby JS"
						/>
						<h3 className="tool-label">Gatsby.js</h3>
					</a>
				</li>
				<li className="tool">
					<a href="https://reactjs.org" target="_blank">
						<img
							className="tool-shape"
							src="icons/reactjs-icon.svg"
							alt="React JS"
						/>
						<h3 className="tool-label">React.js</h3>
					</a>
				</li>
				<li className="tool">
					<a href="https://nodejs.org" target="_blank">
						<img
							className="tool-shape"
							src="icons/node-icon.svg"
							alt="Node JS"
						/>
						<h3 className="tool-label">Node.js</h3>
					</a>
				</li>
				<li className="tool">
					<a href="https://graphql.org" target="_blank">
						<img
							className="tool-shape"
							src="icons/graph-ql-icon.svg"
							alt="GraphQL"
						/>
						<h3 className="tool-label">GraphQL</h3>
					</a>
				</li>
				<li className="tool">
					<a href="https://graphcms.com" target="_blank">
						<img
							className="tool-shape"
							src="icons/graph-cms-icon.svg"
							alt="Graph CMS"
						/>
						<h3 className="tool-label">Graph CMS</h3>
					</a>
				</li>
				<li className="tool">
					<a href="https://www.netlify.com" target="_blank">
						<img
							className="tool-shape"
							src="icons/netlify-icon.svg"
							alt="Netlify"
						/>
						<h3 className="tool-label">Netlify</h3>
					</a>
				</li>
				<li className="tool">
					<img
						className="tool-shape"
						src="icons/google-icon.svg"
						alt="Google"
					/>
					<h3 className="tool-label">Google</h3>
				</li>
				<li className="tool">
					<img
						className="tool-shape"
						src="icons/heart-empty-icon.svg"
						alt="Love"
					/>
					<h3 className="tool-label">Love</h3>
				</li>
				<li className="tool">
					<img
						className="tool-shape"
						src="icons/ghettoblaster-icon.svg"
						alt="New Retro Dark Synthwave"
					/>
					<h3 className="tool-label">New Retro Dark Synthwave</h3>
				</li>
			</ul>
		</div>
	);
};

export default MadeWithLove;
