import React from 'react';
// import { css } from '@emotion/core';

import FooterNav from './footer-nav';

import MadeWithLove from '../../components/accessory/icons/made-with-love';

// NOTE: Pulled off of section.footer-section
// css={css`
// 	padding: 0.5rem calc((100vw - 750px - 1rem) / 2); // < CSS math wizardry
// `}

const Footer = () => (
	<footer className="site-footer section">
		<section className="footer-section main-column-mock">
			<FooterNav />

			<div className="copyright-line">copyright © 2020 Free4m Media</div>
			<MadeWithLove />
		</section>
	</footer>
);

export default Footer;
