import React from 'react';

import GraphCMSprimaryNav from '../navigation_extensions/graphCMS/primary-nav';
import StaticPrimaryMain from '../navigation_extensions/static/primary-nav';

// NOTE: DYNAMIC NAV BASED ON DESIGNATED CMS SOURCE LOCATION
const MainNav = ({ mobileNavIsOpen, toggleNavigation }) => {
	const usingGraphCMS = true;
	const usingStaticNav = false;

	if (usingGraphCMS) {
		return (
			<GraphCMSprimaryNav
				mobileNavIsOpen={mobileNavIsOpen}
				toggleNavigation={toggleNavigation}
			/>
		);
	}

	if (usingStaticNav) {
		return (
			<StaticPrimaryMain
				mobileNavIsOpen={mobileNavIsOpen}
				toggleNavigation={toggleNavigation}
			/>
		);
	}
};

export default MainNav;
