import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import MenuCancelMask from '../menu-cancel-mask';
import DynamicNavLink from '../dynamic-nav-link';

const MainMenuNavigationsQuery = graphql`
	{
		gcms {
			navigations(where: { menuLocationKey: "PRIMARY" }) {
				menuLocationKey
				navigationLabel
				navigationHeader
				navigationNodes {
					siteNodeType
					linkLabel
					url
					sitePage {
						pageSlug
					}
				}
			}
		}
	}
`;

const GraphCMSprimaryNav = ({ mobileNavIsOpen, toggleNavigation }) => {
	const {
		gcms: { navigations },
	} = useStaticQuery(MainMenuNavigationsQuery);

	// console.log('>> TEST MAIN NAVIGATION:', navigations);

	const [menu_1] = navigations;
	const {
		// menuLocation,
		// navigationHeader,
		// navigationLabel,
		navigationNodes,
	} = menu_1;

	// NOTE: DOC BREAKDOWN OF EACH LINK
	// const [link_1] = navigationNodes;
	// const {
	// 	linkLabel,
	// 	siteNodeType,
	// 	url,
	// 	sitePage: {
	// 		pageSlug
	// 	}
	// } =link_1;

	const navClasses = `main-nav${mobileNavIsOpen ? ' show' : ''}`;
	return (
		<nav className={navClasses}>
			<MenuCancelMask toggleNavigation={toggleNavigation} />

			<ul className="main-nav-list w-list-unstyled">
				{navigationNodes.map((linkNode, i) => {
					return (
						<DynamicNavLink
							linkNode={linkNode}
							menuContext="MAIN_NAV"
							index={i}
							key={i}
						/>
					);
				})}
			</ul>
		</nav>
	);
};

export default GraphCMSprimaryNav;
