import React from 'react';
import Helmet from 'react-helmet';
import useSiteMetadata from '../hooks/use-sitemetadata';

import Header from './header/index';
import Footer from './footer/index';

const Layout = ({ children }) => {
	const { title, description } = useSiteMetadata();

	// NOTE: pulled off of main.site-main
	// css={css`
	// 	// Centering magic...
	// 	margin: 2rem auto 4rem;
	// 	max-width: 90vw;
	// 	width: 750px;
	// `}

	return (
		<>
			<Helmet>
				<html lang="en" />
				<title>{title}</title>
				<meta name="description" content={description} />
			</Helmet>

			<Header />
			<div className="vertical-scroll-guard">
				<div className="faux-grid">
					<div className="mask-fade-up"></div>
				</div>
				<main className="site-main">
					<section className="main-section main-column-mock">
						{children}
					</section>
				</main>
				<Footer />
			</div>
		</>
	);
};

export default Layout;
