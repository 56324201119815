import React, { useState } from 'react';
// import styled from '@emotion/styled';
// import { css } from '@emotion/core';
import { Link } from 'gatsby';

import MainNav from './main-nav';

const Header = () => {
	const [mobileNavIsOpen, setNavOpen] = useState(false);

	const toggleNavigation = () => {
		setNavOpen(!mobileNavIsOpen);
	};

	// NOTE: PULLED OFF FROM section.header-section
	// css={css`
	// 	padding: 0.5rem calc((100vw - 750px - 1rem) / 2); // < CSS math wizardry
	// `}

	// TODO: ABSTRACT THE CSS CENTERING IMP BELOW
	return (
		<header className="site-header">
			<div className="mask-fade-down"></div>
			<section className="header-section">
				<MainNav
					mobileNavIsOpen={mobileNavIsOpen}
					toggleNavigation={toggleNavigation}
				/>

				<div className="menu-control">
					<button
						className="mobile-nav-toggle"
						onClick={toggleNavigation}
						onKeyDown={toggleNavigation}
					>
						<div className="hamburger-line">a</div>
						<div className="hamburger-line">a</div>
						<div className="hamburger-line">a</div>
					</button>
				</div>

				<div className="brand">
					<Link className="site-home" to="/" fontWeight="bold">
						<img
							className="depc-logo"
							src="images/brand/DEParadise_logo_wht-p-500.png"
							alt="DEParadise Design Web Creative"
						/>
					</Link>
				</div>
			</section>
		</header>
	);
};

export default Header;
