import React from 'react';

import GraphCMSfooterNavByThree from '../navigation_extensions/graphCMS/footer-nav-by-three';
import StaticFooterNav from '../navigation_extensions/static/footer-nav';

// NOTE: DYNAMIC NAV BASED ON DESIGNATED CMS SOURCE LOCATION
const FooterNav = () => {
	const usingGraphCMS = true;
	const usingStaticNav = false;

	if (usingGraphCMS) {
		return <GraphCMSfooterNavByThree />;
	}

	if (usingStaticNav) {
		return <StaticFooterNav />;
	}
};

export default FooterNav;
