import React from 'react';

const MenuCancelMask = ({toggleNavigation}) => {
	return (
		<area
			className="menu-cancel-mask"
			alt="cancel menu navigation"
			role="tab"
			onClick={toggleNavigation}
			onKeyDown={toggleNavigation}
		></area>
	);
};

export default MenuCancelMask;
