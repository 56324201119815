import React from 'react';
import { Link } from 'gatsby';

const DynamicNavLink = ({ linkNode, menuContext, index }) => {
	let { siteNodeType } = linkNode;
	// console.log('Generating PAGE NAV LINK:', linkNode, menuContext);

	// Check menuContext for class option assignment
	const forMainNav = menuContext === 'MAIN_NAV';
	const navItemClass = forMainNav ? 'main-nav-item' : 'nav-item';
	const navLinkClass = forMainNav ? 'main-nav-link' : 'nav-link';

	if (siteNodeType === 'PAGE') {
		// console.log('INTERNAL LINK:', linkNode.linkLabel);

		// // NOTE: If PAGE SLUG does not have "/" it will assign the "to" property as a sub-node page
		const pageSlug = `/${linkNode.sitePage.pageSlug}`;

		// TODO: << account for sub-navigation based on menuContext including "*_SUB_NAV"<<
		// TODO: DIFF between main-nav-link and nav-link
		return (
			<li key={pageSlug} className={navItemClass}>
				<Link
					to={pageSlug}
					activeClassName="w--current"
					className={navLinkClass}
				>
					{linkNode.linkLabel}
				</Link>
			</li>
		);
	}

	if (siteNodeType === 'POST') {
	} // TODO: << INTEGRATE <<
	if (siteNodeType === 'PROJECT') {
	} // TODO: << INTEGRATE <<

	// FIX: RE-INTEGRATE MENU CONTEXT FOR MAIN NAV HANDLE
	if (siteNodeType === 'EXTERNAL' && forMainNav) {
		console.log('EXTERNAL LINK:', linkNode.linkLabel);
		const itemKey = `${linkNode.linkLabel}_${index}`;
		return (
			<li key={itemKey} className={navItemClass}>
				<a
					href={linkNode.url}
					target="_blank"
					className={navLinkClass}
					rel="noreferrer"
				>
					{linkNode.linkLabel}
				</a>
			</li>
		);
	}

	// EXT SOCIAL INLINE ICONS
	if (
		siteNodeType === 'EXTERNAL' &&
		menuContext.includes('social-links_INLINE_ICONS')
	) {
		let classNamesDerived = `${linkNode.customLinkClasses} w-inline-block`;
		return (
			<a
				href={linkNode.url}
				target="_blank"
				className={classNamesDerived}
			></a>
		);
	}

	if (siteNodeType === 'ANCHOR') {
	} // TODO: << INTEGRATE <<

	if (siteNodeType === 'EMAIL') {
		// let mailToParams = `?subject=${linkNode.mailToSubject}&body=${linkNode.mailToBody}`;
		// console.log('>>>> TEST TEST NAV NODE:', linkNode, mailToParams);

		// const derivedEmailHREF = `mailto:${linkNode.mailTo}${mailToParams}`;

		return <span>{linkNode.linkLabel}</span>;
		// return <a href={derivedEmailHREF}>{linkNode.linkLabel}</a>;
	}

	if (siteNodeType === 'PHONE') {
	} // TODO: << INTEGRATE <<
	if (siteNodeType === 'FILE') {
	} // TODO: << INTEGRATE <<

	//

	console.error(
		'<!!!> DYNAMIC NAV LINK DOES NOT SUPPORT SITE NODE TYPE:',
		siteNodeType,
		'on',
		menuContext
	);
	return (
		<li key={`error_${index}`} className="main-nav-item">
			Broken_link_for {linkNode.linkLabel}
		</li>
	);
};

export default DynamicNavLink;
