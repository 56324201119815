import React from 'react';
import DynamicNavLink from './dynamic-nav-link';

const FooterNavColumn = ({ nav, menuContext, columnBy, index }) => {
	const { listStyle, navigationHeader, navigationNodes } = nav;
	const position = index + 1;

	// console.log(
	// 	'\n>> FOOTER NAV COLUMN WILL RENDER:',
	// 	'\n - NAVIGATION:',
	// 	nav,
	// 	'\n - LIST STYLE:',
	// 	listStyle,
	// 	'\n - NAVIGATION HEADER:',
	// 	navigationHeader,
	// 	'\n - NAVIGATION NODES',
	// 	navigationNodes,
	// 	''
	// );

	let listRender;
	let generatingSocialLinks = nav.menuLocationKey.includes('SOCIALS');

	if (listStyle === 'ORDERED_LIST') {
		// TODO: << INTEGRATE
		listRender = <h3>ORDERD: TBD</h3>;
	}
	if (listStyle === 'UNORDERED_LIST') {
		listRender = (
			<ul className="nav-group-list w-list-unstyled">
				{navigationNodes.map((linkNode, i) => {
					return (
						<DynamicNavLink
							linkNode={linkNode}
							menuContext={menuContext}
							index={i}
							key={i}
						/>
					);
				})}
			</ul>
		);
	}

	if (listStyle === 'INLINE') {
		let socialLinks = generatingSocialLinks ? 'social-links' : '';
		let classNamesDerived = `${socialLinks} w-clearfix`;
		let derivedMenuContext = `${menuContext}_${socialLinks}_${listStyle}_ICONS`;

		listRender = (
			<div className={classNamesDerived}>
				{navigationNodes.map((linkNode, i) => {
					return (
						<DynamicNavLink
							linkNode={linkNode}
							menuContext={derivedMenuContext}
							index={i}
							key={i}
						/>
					);
				})}
			</div>
		);
	}
	if (listStyle === 'RANDOM') {
		// TODO: << INTEGRATE
		listRender = <h3>UNORDERD: TBD</h3>;
	}
	if (listStyle === 'NONE') {
		listRender = (
			<>
				{navigationNodes.map((linkNode, i) => {
					return (
						<DynamicNavLink
							linkNode={linkNode}
							menuContext={menuContext}
							index={i}
							key={i}
						/>
					);
				})}
			</>
		);
	}

	return (
		<div className={`col-${columnBy}-${position} nav-group w-col w-col-4`}>
			<div className="nav-group">
				<h3 className="nav-group-heading">{navigationHeader}</h3>
				{listRender}
			</div>
		</div>
	);
};

export default FooterNavColumn;
