import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import FooterNavColumn from '../footer-nav-column';

const FooterMenuNavigationsQuery = graphql`
	{
		gcms {
			navigations(where: { menuLocationKey_contains_some: "FOOTER" }) {
				menuLocationKey
				# navigationLabel
				navigationHeader
				listStyle
				navigationNodes {
					siteNodeType
					linkLabel
					url
					mailTo
					mailToBody
					mailToSubject
					customLinkClasses
					sitePage {
						pageSlug
					}
				}
			}
		}
	}
`;

const GraphCMSfooterNavByThree = () => {
	const {
		gcms: { navigations },
	} = useStaticQuery(FooterMenuNavigationsQuery);

	// console.log('>> TEST FOOTER NAVIGATION COLLECTION:', navigations);

	const footerTargetNavigations = ['SECONDARY', 'SOCIALS', 'CONTACT']; // TODO: << ADD THIS TO STATIC PROJECT CONFIG
	const validatedNavigations = footerTargetNavigations.map(target => {
		return navigations.find(nav => nav.menuLocationKey.includes(target));
	});

	return (
		<div className="col-x-3 w-row">
			{validatedNavigations.map((nav, i) => {
				return (
					<FooterNavColumn
						nav={nav}
						menuContext={`FOOTER_${i}`}
						columnBy={3}
						index={i}
						key={i}
					/>
				);
			})}
		</div>
	);
};

export default GraphCMSfooterNavByThree;
